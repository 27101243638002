import React from 'react';
import classNames from 'classnames';

import style from './Input2.scss';

const Input = ({ name, value, onChange, type, required, labelName, className, ...restProps }) => {
  return (
    <div className={classNames(style.input, className)}>
      <label className={style.label}>{labelName}</label>
      <input
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        required={required}
        className={style.element}
        {...restProps}
      />
      <span className={style.highlight} />
      <span className={style.bar} />
    </div>
  );
};

export default Input;
